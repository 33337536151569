<template>
  <div
    class="nav-mega-flyout"
    @click="closeOnClick()"
    @keydown="closeOnClick()"
  >
    <div class="nav-mega-flyout__inner" @click.stop>
      <AppNavMegaFlyoutSidebar :data="data[activeMenuIndex]" />
      <transition name="fade-in">
        <AppNavMegaFlyoutContent
          v-if="activeLevel2Index !== -1 && activeLevel3Index !== -1"
          :data="data[activeMenuIndex]?.children[activeLevel2Index]?.children[activeLevel3Index]"
          :content="data[activeMenuIndex]?.children[activeLevel2Index]?.content"
        />
      </transition>
      <div class="nav-mega-flyout__close">
        <AppIcon
          icon="close"
          :is-raw="true"
          @click="flyoutStore.closeFlyout()"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
    data: {
        type: Object,
        required: true,
    },
    activeMenuIndex: {
        type: Number,
        required: true,
    },
    activeLevel2Index: {
        type: Number,
        required: true,
    },
    activeLevel3Index: {
        type: Number,
        required: true,
    },
});

/* Close flyout on click outside */
const flyoutStore = useFlyoutStore();

const closeOnClick = () => {
    flyoutStore.closeFlyout();
};

</script>

<style lang="scss" scoped>
.nav-mega-flyout {
    position: fixed;
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    padding: 20px;
    filter: drop-shadow(30px 30px 60px rgba(0, 62, 48, 0.1));
}

.nav-mega-flyout__inner {
    @include grid-layout();

    position: relative;
    height: 100%;
    border-radius: 12px;
    background-color: $C_WHITE;
    grid-template-columns: 1fr 2fr;
    -ms-overflow-style: none;  /* IE and Edge */
    overflow-y: auto;
}

.nav-mega-flyout__close {
    position: absolute;
    top: 30px;
    right: 30px;
    color: $C_PRIMARY;

    &:hover {
        cursor: pointer;
    }

    svg {
        height: 20px;
    }
}
</style>
